import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-recursion-with-functional-programming",
  "date": "2016-10-20",
  "title": "ES6 RECURSION WITH FUNCTIONAL PROGRAMMING",
  "author": "admin",
  "tags": ["development", "javascript", "es6"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article, we are going to use ES6 recursion with functional programming to understand how we can implement list operations like map, reduce, filter and join."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Head & Tail`}</h2>
    <p>{`When functions are run recursively over lists, we can model those lists as a `}<inlineCode parentName="p">{`head`}</inlineCode>{` and a `}<inlineCode parentName="p">{`tail`}</inlineCode>{`.`}</p>
    <p>{`The head is the first element of the list, and the tail is the rest.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const [ head, ...tail ] = [ 1, 2, 3, 4 ];
// head: 1
// tail: 2, 3, 4
`}</code></pre>
    <p>{`In ES6, we can use destructuring for retrieving the tail as above.`}</p>
    <p>{`Doing things functional, we can implement the head and tail functions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const getHead = ([ head, ...tail ]) => head;
const getTail = ([ head, ...tail ]) => tail;
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/images/2016-10-20-es6-recursion-with-functional-programming/pooh.jpg",
        "alt": "Winnie The Pooh Eating Recursion"
      }}></img></p>
    <h2>{`(Tail) Recursion`}</h2>
    <p>{`We can implement functions that operate over arrays using `}<a parentName="p" {...{
        "href": "/developer/javascript/es6/es6-parameter-handling/"
      }}>{`parameter`}</a>{` destructuring and recursion, for example map function.`}</p>
    <Message type='info' title='List' content='Arrays are called lists in functional programming.' mdxType="Message" />
    <p>{`Let’s start by implementing the map function in ES6.`}</p>
    <h2>{`Map`}</h2>
    <p>{`Map takes a list and a function, and returns a list containing the result of the function applied to each element of the list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function map([head, ...tail], fn) {
  if (head === undefined && !tail.length) {
    return [];
  } else {
    return tail.length === 0 ? // highlight
      [fn(head)] : [fn(head), ...map(tail, fn)];
  }
}
`}</code></pre>
    <p>{`Line 5: the `}<inlineCode parentName="p">{`tail.length === 0`}</inlineCode>{` checks if there is still a tail to recurse over. Otherwise, the recursion stops there.`}</p>
    <p>{`This function can be used to test like follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const items = [1, 2, 3, 4];
 
console.log(map(items, item => item + 1));
// 2, 3, 4, 5
`}</code></pre>
    <p>{`Next, look at how we can implement filter method.`}</p>
    <h2>{`Filter`}</h2>
    <p>{`The filter() method creates a new array with all elements that pass the test implemented by the provided function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function filter([ head, ...tail ], fn) {
  const newHead = fn(head) ? [ head ] : [];
  return tail.length ? [ ...newHead, ...(filter(tail, fn)) ] : newHead;
}
`}</code></pre>
    <p>{`This function can be used to test like follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const items = [1, 2, 3, 4];
 
console.log(filter(items, item => item % 2 === 0));
// 2, 4
`}</code></pre>
    <p>{`Let’s look at the `}<inlineCode parentName="p">{`reduce`}</inlineCode>{` method, next.`}</p>
    <h2>{`Reduce`}</h2>
    <p>{`The reduce() method applies a function against an accumulator and each value of the array (from left-to-right) to reduce it to a single value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function reduce([ head, ...tail ], fn, initial) {
  if(head === undefined && tail.length === 0) {
    return initial;
  }
 
  if(!initial) {
    const [ newHead, ...newTail] = tail;
    return reduce(newTail, fn, fn(head, newHead));
  }
 
  return tail.length ?
    reduce(tail, fn, fn(initial, head)) : [ fn(initial, head) ];
}
`}</code></pre>
    <p>{`Finally, let’s look at the `}<inlineCode parentName="p">{`join`}</inlineCode>{` method.`}</p>
    <h2>{`Join`}</h2>
    <p>{`The join() method joins all elements of an array into a string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function join([ head, ...tail ], separator = ',') {
  if (head === undefined && !tail.length) {
    return '';
  }
 
  return tail.length ? head + separator + join(tail, separator) : head;
}
`}</code></pre>
    <p>{`This function can be used to test like follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const items = [1, 2, 3, 4];
 
console.log(join(items));
// 1, 2, 3, 4
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`The functions we implemented are already in ES5 and above, but doing so in recursive functions in ES6 gives you an in-depth understanding on how to use functional programming and ES6 effectively`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      